.user-image-round {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
    object-fit: cover;
}
.center-marker-full {
    position: absolute;
    top: 48%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;
}
.search-input-location {
    // outline: none;
    // border: none;
    width: 100%;
    padding: 0.5rem;
}
.search-input-location:hover,
.search-input-location:focus {
    outline: none !important;
    // border: none !important;
    box-shadow: none !important;
    width: 100%;
    padding: 0.5rem;
}
.setlocation-address-type {
    background-color: #fff;
    /* border-radius: 24px 24px 0px 0px; */
  }
.mappp{
    // position: absolute !important;
    width: 50% !important;
    height: 100% !important;
    // top: 500px;
    right: 0px;
    bottom: 0px;
}  
.closeInMap { 
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    position: absolute;
    z-index: 9999;
    right: 17px;
    top: 10px;
}
.btn-dasbordItems{
    display: flex;
    align-items: center;
}
.btn-dasbordItems:hover{
    background-color: red;
}



    