.inputCheck input:hover + span {
    background-color: #191;
    color: #fff;
  }
  
  .btn-b {
    background-color: #293950;
    color: #fff;
  }
  
  .img-cb {
    list-style-type: none;
  }
  
  .img-li {
    display: inline-block;
  }
  
  input[type="checkbox"][id^="cb"] {
    display: none;
  }
  
  #store-card-label {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  #store-card-label::before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #00365f;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  #store-card-label img {
    height: 100px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
   #special > .inputCheck:checked + #store-card-label {
    border-color: #fcfeff;
  }
  
  #special > .inputCheck:checked + #store-card-label::before {
    content: "✓";
    background-color: #00365f;
    transform: scale(1);
  }
  
  #special > .inputCheck:checked + #store-card-label img {
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
  }
  